<template>
  <div @scroll="handleScroll">
    <loading
      :active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <section class="header p-2" algin="center">
      <center>
        <a href="https://www.revivify.com/">
          <img
            class="revivify-logo"
            height="55px"
            :src="require('@/assets/images/logo/new_logo.png')"
            alt="Revivify Logo"
          />
        </a>
      </center>
    </section>
    <div class="back_to_home">
      <a href="https://www.revivify.com/">Back To Home</a>
    </div>
    <div class="arrow_btn_fq">
      <a
        :href="scroll <= 200 ? '#seller_tab' : '#'"
        class="arrow_btn"
        @click="ShowFun(upperarrow)"
        ><feather-icon
          class="down"
          :icon="scroll <= 200 ? 'ChevronDownIcon' : 'ChevronUpIcon'"
      /></a>
    </div>
    <b-row>
      <b-col sm="12" lg="12" md="12" class="text-center banner-text">
        <p>SELLING YOUR LUXURY FASHION HAS NEVER BEEN EASIER</p>
        <h1>CREATE YOUR SELLER ACCOUNT NOW</h1>
      </b-col>
      <b-col sm="12" lg="12" md="12" class="text-center">
        <p class="fs-20">
          If you have an existing Revivify Account you don't need to create a
          different Seller Account,
          <u
            ><a v-b-modal.model-login-seller-account @click="checkForm('LOGIN')"
              >Click Here</a
            ></u
          >
          to login.
        </p>
      </b-col>
    </b-row>
    <b-container class="seller_form_container">
      <!-- form -->
      <validation-observer ref="registerForm">
        <b-form class="auth-register-form mt-2">
          <b-row class="sell_form">
            <b-col md="6" sm="12" cols="12" v-if="isMobile()">
              <iframe
                class="sell_addver"
                width="100%"
                src="https://www.youtube.com/embed/ZauridN8-rM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </b-col>
            <b-col md="6" sm="12" cols="12">
              <b-row>
                <b-col md="6" sm="12" cols="12" class="mt-2 mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="registerForm.fname"
                      id="input-1"
                      type="text"
                      class="seller_form"
                      placeholder="First Name"
                      :state="errors.length > 0 ? false : null"
                      required
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="6" sm="12" cols="12" class="mt-2 mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="registerForm.lname"
                      id="input-2"
                      type="text"
                      class="seller_form"
                      placeholder="Last Name"
                      :state="errors.length > 0 ? false : null"
                      required
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                  cols="12"
                  class="mt-2 mb-2 tel_input_main"
                >
                  <validation-provider
                    class="phone_input"
                    #default="{ errors }"
                    name="Phone"
                    rules="required"
                  >
                    <b-form-input
                      v-model="registerForm.phone"
                      id="input-3"
                      type="number"
                      class="seller_form"
                      placeholder="Phone"
                      :state="errors.length > 0 ? false : null"
                      required
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <v-select
                    single
                    :reduce="(countryCode) => countryCode.code"
                    label="code"
                    class="code_input"
                    placeholder="Code"
                    v-model="registerForm.phone_code"
                    :options="countryCode"
                    :clearable="false"
                    :selectable="
                      (countryCode) => countryCode.code !== 'disabled'
                    "
                  >
                    <template #selected-option="data">
                      <span style="margin-top: -3px"> {{ data.code }}</span>
                    </template>
                    <template #option="data">
                      <span class="dropdown_code">
                        {{
                          data.code != "disabled"
                            ? data.code + " " + data.name
                            : data.name
                        }}</span
                      >
                    </template>
                  </v-select>
                </b-col>

                <b-col md="6" sm="12" cols="12" class="mt-2 mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                  >
                    <b-form-input
                      id="input-4"
                      v-model="registerForm.email"
                      type="email"
                      class="seller_form"
                      placeholder="Enter Email"
                      :state="errors.length > 0 ? false : null"
                      required
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="6" sm="12" cols="12" class="mt-2 mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        name="register-password"
                        id="input-5"
                        v-model="registerForm.password"
                        class="seller_form"
                        placeholder="Password"
                        required
                      />
                      <b-input-group-append is-text class="eye_icon">
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="6" sm="12" cols="12" class="mt-2 mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    rules="required"
                  >
                    <b-form-select
                      v-model="registerForm.country"
                      class="seller_country"
                    >
                      <b-form-select-option value="" disabled>
                        Select Country
                      </b-form-select-option>

                      <b-form-select-option
                        v-for="(item, index) in countryCode"
                        :key="index"
                        :value="item.code"
                        :disabled="item.code === 'disabled'"
                      >
                        {{ item.name }}
                      </b-form-select-option>
                    </b-form-select>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6" sm="12" cols="12" v-if="!isMobile()">
              <iframe
                class="sell_addver"
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/ZauridN8-rM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </b-col>
          </b-row>

          <b-row class="sell_subscribe">
            <b-col cols="12 mt-3">
              <b-form-group>
                <b-form-checkbox
                  :value="1"
                  :unchecked-value="0"
                  v-model="registerForm.newsletter"
                  id="chekbox-1"
                  name="seller_checkbox_1"
                >
                  Subscribe to Revivify email updates on products, collections,
                  special offers and events.
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <b-form-checkbox
                  id="chekbox-2"
                  name="seller_checkbox_2"
                  v-model="check2Status"
                >
                  Please confirm you have read and accept our
                  <b-link
                    href="../../terms-conditions-for-clients-within-india"
                    class="seller_link"
                    target="_blank"
                    >Terms & Conditions.</b-link
                  >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Create Button -->
          <b-row class="justify-content-center">
            <b-col class="text-center col-12">
              <b-button
                variant="dark"
                class="btn-seller m-2"
                type="submit"
                @click.prevent="validationForm"
              >
                CREATE SELLER ACCOUNT
              </b-button>
              <b-button
                variant="dark"
                class="btn-seller m-2"
                type="submit"
                v-b-modal.model-login-seller-account
                @click="checkForm('LOGIN')"
              >
                LOGIN TO SELLER ACCOUNT
              </b-button>
            </b-col>
            <!-- <b-link v-b-modal.modal-create-stripe-link  @click="checkForm('LOGIN')" class="mt-2">Already Have a Seller Account ?</b-link> -->
          </b-row>
        </b-form>
      </validation-observer>

      <b-row class="mt-2">
        <b-col class="text-center">
          <!-- <p class="seller_text">
            Link your account to Revivify stripe connect to recieve payments on
            sold items
            <b-link
              @click="checkForm('STRIPE')"
              id="modal-create-stripe-link"
              v-b-modal.modal-create-stripe-link
              class="seller_link"
              >here.</b-link
            >
          </p> -->
          <h4 class="text_link pb-2">
            <p class="seller_link" style="text-decoration: none">
              For details regarding how your information will be used. Please
              visit our
              <b-link
                href="../../privacy-policy"
                class="seller_link"
                target="_blank"
                >Privacy Policy</b-link
              >
              and
              <b-link
                href="../../terms-conditions-for-clients-within-india"
                class="seller_link"
                target="_blank"
              >
                Term and Conditions.</b-link
              >
            </p>
          </h4>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid id="seller_tab" class="bg_light">
      <b-row>
        <b-col>
          <div class="p-10">
            <b-tabs pills card vertical class="tab_heading">
              <b-tab title="SELL WITH US +" active
                ><b-card-text>
                  <h1 class="tab-11">OPEN YOUR SELLER ACCOUNT</h1>
                  <p>
                    To start selling, you will first need to create your seller
                    account. For this we ask you to provide your details and
                    also link to Stripe Connect in order to make prompt payments
                    once your item sells.
                  </p>
                  <h1 class="tab-1 mt-3">SUBMIT YOUR LISTING</h1>
                  <p>
                    Next, you will be prompted to submit your listing by
                    <b>uploading your item images</b> in keeping with the stated
                    guidelines. For this, you will be required to
                    <b>describe your item</b>, including its designer name and
                    if possible, an estimate year of purchase and original
                    purchase price. If you have more items to sell, simply click
                    "add item", or continue to
                    <b>submit your piece for approval.</b> A member of our team
                    will then get back to you within <b>24 hours</b> to let you
                    know if your item has been approved along with a suggested
                    selling price.<br /><br />
                    We understand that you may be worried about how you can
                    safely get your items to our studio. The great news is –
                    <b>collection is on us!</b> Meaning all you need to do is
                    make sure it is securely packaged up, and our trusted
                    delivery partner will do the rest.<br /><br />
                    In advance of your upload, we recommend referring to our
                    list of
                    <a
                      href="../../designers/"
                      class="seller_link"
                      target="_blank"
                      >accepted brands.</a
                    >
                  </p>
                  <h1 class="tab-2 mt-3">Await authentication and valuation</h1>
                  <p>
                    We will notify you via email to confirm your items have
                    reached us. Next, our expert team will authenticate and
                    value each one (in case of minor discrepancies from your
                    submitted listing) and communicate with you to agree a
                    price. Once an agreement has been made, our in-house
                    professionals will
                    <b> photograph your pieces and launch your listing.</b>
                  </p>
                  <h1 class="tab-3 mt-3">Start selling and earning</h1>
                  <p>
                    The Revivify team takes care of the entire selling process
                    from beginning to end, so you can get back to what's
                    important to you. For those who like to keep informed, our
                    unique platform and dashboard allow you to track your items,
                    providing an overview of those which are currently listed,
                    sold, and finalized.
                  </p>
                </b-card-text></b-tab
              >
              <b-tab title="SELLER PAYMENTS +">
                <h1 class="tab-4">Seller Payments</h1>
                <p class="seller_Pay">
                  For every successful sale, revivify keeps<br />
                  <b>35%</b> of the valued item price as the commission.
                </p>
                <b-card-text> </b-card-text>
              </b-tab>
              <b-tab title="VALUATION +"
                ><b-card-text
                  ><h1 class="tab-5">VALUATION</h1>
                  <p>
                    We understand that, for first-time sellers, pricing can feel
                    like a minefield. With Revivify, our team is with you every
                    step of the way. Once you have added your item to your
                    seller account, along with your own clear imagery, our team
                    will get in touch within 24 hours to confirm pricing. If you
                    are happy, delivery will then be arranged with our trusted
                    courier ready for authentication and professional
                    photography at our studio prior to listing.
                  </p>
                </b-card-text></b-tab
              >
              <b-tab title="SELLER DASHBOARD +"
                ><b-card-text>
                  <h1 class="tab-6">SELLER DASHBOARD</h1>
                  <p>
                    Once you create your seller account, you will have 24/7
                    access to your unique dashboard, where you can manage your
                    notifications and messages with the Revivify concierge. Here
                    you will also be able to view past sales, potential
                    earnings, and track the status of your items.<br /><br />
                    Along with keeping you informed throughout the seller
                    journey, from approval to sold, you will also have the
                    chance to boost your pieces for increased visibility to
                    increase the speed of your sale. As items are added to our
                    inventory, your listing will naturally move further down our
                    listings. This advanced promotional tool allows you to
                    position your item back to the top pages 2 times through the
                    year for better visibility. Explore more by creating your
                    seller account.<br /><br />Whenever you make a sale, you can
                    use our innovative, one-click invoice generation button to
                    receive payment almost instantly.
                  </p></b-card-text
                ></b-tab
              >
              <b-tab title="HOLDING YOUR ITEM +"
                ><b-card-text
                  ><h1 class="tab-7">HOLDING YOUR ITEM</h1>
                  <p>
                    While we will hold your item at Revivify HQ for one year
                    before shipping it back to you (in case of an unsuccessful
                    sale), we understand that there may be an occasion during
                    the period your item is with us that you wish to make use of
                    your item. To have your item returned to you and delisted,
                    simply click its checkout button via your seller dashboard.
                    Please note that you will be responsible for the shipment
                    pick up and cost, in addition to a 5% charge of the listing
                    price to cover Revivify for collection, product photography
                    and other costs borne. To relist your item, you can log back
                    into your dashboard at any time and reactivate your listing.
                  </p>
                </b-card-text></b-tab
              >
              <!-- <b-tab title="REFURBISH +"
                ><b-card-text
                  ><h1 class="tab-8">Refurbish</h1>
                  <p>
                    When it comes to heirloom pieces or an outfit close to your
                    heart that's not currently in season, what if you could
                    transform it into something that's wearable today? Our
                    design team can help you to breathe new life into cherished
                    pieces that might be sitting at the back of your wardrobe,
                    by refurbishing them into a whole new style. Imagine taking
                    your mum's wedding heirloom and turning it into a fabulous
                    skirt, kurta or jacket? Or even taking your most-loved
                    embroidered pattern and placing it on another ensemble? You
                    can list your item for refurbishment within your seller
                    dashboard or get in touch with our concierge to discuss any
                    ideas.
                  </p>
                </b-card-text></b-tab
              > -->
              <b-tab title="BRAND WE ACCEPT +"
                ><b-card-text
                  ><h1 class="tab-9">Brands We Accept</h1>
                  <p>
                    We know that many of our clients will be seeking a specific
                    piece by a specific designer, which is why we accept an
                    extensive list of brands at Revivify. <br /><br />
                    However, as we always want to ensure that we offer the best
                    curated edit from each, never flooding the site with repeat
                    items, or those out of season, please understand that every
                    piece by every brand will be approved on a case-by-case
                    basis. <br /><br />
                    We recommend referring to our list of
                    <a
                      href="../../designers/"
                      class="seller_link"
                      target="_blank"
                      >accepted brands.</a
                    >
                  </p>
                </b-card-text></b-tab
              >
              <b-tab title="SELLER FAQ'S +">
                <b-card-text class="tab-10">
                  <app-collapse
                    class="pl-2 pr-2"
                    accordion
                    :type="collapseType"
                    v-for="(item, index) in helpdesk"
                    :key="index + 'collapse_item'"
                  >
                    <div @click="collapseClicked(item)">
                      <app-collapse-item
                        v-if="PageTitle == item.PageFaq ? true : false"
                        :title="item.PageFaq + ' +'"
                      >
                        <div class="text_p" v-html="item.PageFaqAns"></div>
                      </app-collapse-item>
                      <app-collapse-item v-else :title="item.PageFaq + ' +'">
                        <div class="text_p" v-html="item.PageFaqAns"></div>
                      </app-collapse-item>
                    </div>
                  </app-collapse>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center Shop_page">
          <h1>join the community</h1>
          <p>
            By subscribing, i agree to recieve email updates from Revivify. I
            can withdraw consent at any point
          </p>
          <b-form-input
            v-model="sub_email"
            id="email_input"
            type="email"
            class="input_email"
            placeholder="Enter Email Address"
          ></b-form-input>
          <b-button
            variant="dark"
            class="btn-seller"
            type="submit"
            @click="subscribe()"
          >
            Subscribe
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <!-- modal -->
    <b-modal
      centered
      class="model_header"
      id="modal-create-stripe-link"
      ref="my-modal"
      title="Link your account to Revivify stripe connect"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <validation-observer ref="sellerLoginForm">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col md="12" class="p-1">
              <label> Email </label>

              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="seller-email"
                  v-model="sellerEmail"
                  :state="errors.length > 0 ? false : null"
                  required
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="12" class="p-1">
              <label> Password </label>

              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="register-password"
                    v-model="sellerPassword"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal
      centered
      class="model_header"
      id="model-login-seller-account"
      ref="my-modal"
      title="Login to your Seller Account"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <validation-observer ref="sellerLoginForm">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col md="12" class="p-1">
              <label> Email </label>

              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="seller-email"
                  v-model="sellerEmail"
                  :state="errors.length > 0 ? false : null"
                  required
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="12" class="p-1">
              <label> Password </label>

              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="register-password"
                    v-model="sellerPassword"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <div class="d-flex justify-content-end forgot_btn">
                  <small class="text-danger mr-auto">{{ errors[0] }}</small>
                  <a
                    target="_blank"
                    href="https://www.revivify.com/my-account/lost-password/"
                  >
                    <span>Forgot Password?</span>
                  </a>
                </div>
              </validation-provider>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>

    <!-- Footer -->
    <footer class="footer footer-light_n">
      <slot name="footer">
        <app-foQoter />
      </slot>
    </footer>
    <!-- /Footer -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import AppFooter from "@core/layouts/components/CreateSellerFooter.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthServices from "@/apiServices/AuthServices";
import StripeVendorServices from "@/apiServices/StripeVendorServices";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { required, email } from "@validations";
import { country_code } from "../ProfileAccountSettings/countryCode";
import swal from "sweetalert2";
import axios from "axios";

window.swal = swal;

import {
  BRow,
  BCol,
  BContainer,
  BLink,
  BButton,
  BTab,
  BTabs,
  BCard,
  BCardHeader,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BCardText,
  BForm,
  BFormRow,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  metaInfo: {
    title: "Revivify | How to Create an account",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Create an account on Revivify with these easy steps and join the #RevivifyRevolution where you begin your sustainable fashion journey with us!' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  components: {
    BFormInput,
    BRow,
    BCol,
    BContainer,
    BFormGroup,
    BFormCheckbox,
    BLink,
    BButton,
    BTab,
    BTabs,
    BCardText,
    BCardHeader,
    BForm,
    BFormRow,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    AppCollapse,
    AppCollapseItem,

    // validations
    ValidationProvider,
    ValidationObserver,
    Loading,

    AppFooter,
  },

  mixins: [togglePasswordVisibility],

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },

  data() {
    return {
      required,
      upperarrow: false,
      lowerarrow: true,
      scroll: 0,

      flag: true,
      PageTitle: "",
      interval: null,
      collapseType: "margin",

      status: "accepted",

      helpdesk: [
        {
          id: 1,
          PageFaq: "What items can I sell?",
          PageFaqAns:
            "We welcome clothing, accessories, bags and jewellery from our <a>brand list</a> in a resalable <a>condition</a>.",
        },
        {
          id: 2,
          PageFaq: "How do you reach a valuation?",
          PageFaqAns:
            "We closely examine global market data to ascertain the price at which the same or similar items are being sold. Our unique expertise allows us to price competitively, taking into consideration the brand, popularity, condition and more.",
        },
        {
          id: 3,
          PageFaq: "What if I do not agree with the valuation?",
          PageFaqAns:
            "No problem! We only collect the item once a valuation has been agreed.",
        },
        {
          id: 4,
          PageFaq: "How should I photograph my item for approval?",
          PageFaqAns:
            "We ask that you take images of the front and back of your item, including any wear and tear or damage. It is important to highlight aspects such as loose threads and missing embellishments. We also recommend choosing a spot with plenty of natural sunlight and, if possible, positioning it against a solid colour background.",
        },
        {
          id: 5,
          PageFaq: "What is included as part of our white glove service?",
          PageFaqAns:
            "We take care of the entire sales process from end to end, including the valuation, photography, listing and delivery of your item. Our friendly and knowledgeable team are always on hand to answer any questions you might have, with an emphasis on making the process as seamless as possible for our buyers and sellers alike. This includes offering a free collection service within India, UK, EU, USA and UAE.",
        },
        {
          id: 6,
          PageFaq: "When will I receive my payment?",
          PageFaqAns:
            "Once your item has been purchased, you will be able to create your one-click invoice within your seller dashboard and receive payment almost instantly – without fuss or back and forth communication.",
        },
        {
          id: 7,
          PageFaq: "I submitted a listing, why haven’t I received an offer? ",
          PageFaqAns:
            "The first step is to check your seller dashboard. If there is no change or notification of an offer within 4 business days, please get in touch with the Revivify team.",
        },
        {
          id: 8,
          PageFaq: "How can I contact Revivify? ",
          PageFaqAns:
            "You can contact our friendly team through your seller dashboard or by sending an email to wecare@revivify.com",
        },
        {
          id: 9,
          PageFaq: "How do I price my item?",
          PageFaqAns:
            "We understand that for first-time sellers, pricing can feel like a minefield. If you’re interested in the current, pre-loved market price for your piece, then you are welcome to email us at seller@revivify.com. Please use the subject line “Pricing” to help us attend to your enquiry as quick as possible. Once uploaded to your seller account, along with your own clear imagery, our team will get in touch within 24 hours to confirm pricing. If you are happy, delivery will then be arranged with our trusted courier, ready for authentication and professional photography at our studio.",
        },
      ],

      registerForm: {
        fname: "",
        lname: "",
        phone: "",
        phone_code: "",
        email: "",
        password: "",
        country: "",
        newsletter: 1,
      },

      check1Status: false,
      check2Status: false,
      isLoading: false,
      fullPage: true,

      coutryCodeSelected: "",
      coutryCodeSelectedNew: "",
      countryCode: country_code,

      sellerEmail: "",
      sellerPassword: "",

      formType: "",
      sub_email: null,
      nonce: null,
      getCountry: null,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    getNonce() {
      let newURL = window.location.protocol + "//" + window.location.host;
      axios.get(newURL + "/wpLoginApi/").then((res) => {
        console.log(res.data.nonce);
        if (res.data.status) {
          this.nonce = res.data.nonce;
        }
      });

      axios
        .get(
          "https://api.ipgeolocation.io/ipgeo?apiKey=fc17cbd3f0614b5db28ecdaea34b0fdd"
        )
        .then((res) => {
          console.log(res.data);
          this.getCountry = res.data;
          if (this.getCountry != null) {
            let Arr = this.countryCode.filter((item) => {
              if (item.country_code == this.getCountry.country_code2) {
                console.log("Happy =>", item.country_code);
                return item;
              }
            });
            if (Arr.length > 0) {
              this.registerForm.phone_code = Arr[0].code;
            }
          }
        });
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },

    handleScroll() {
      const element = document.querySelector(".ohlive-header");
      this.scroll = document.documentElement.scrollTop;
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        element.classList.add("scrolled");
      } else {
        element.classList.remove("scrolled");
        element.classList.add("scrolled");
      }
    },

    ShowFun(upperarrow, lowerarrow) {
      this.upperarrow = upperarrow;
      this.lowerarrow = lowerarrow;
    },
    resetModal() {
      this.sellerEmail = "";
      this.sellerPassword = "";
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },

    async handleSubmit() {
      this.$refs.sellerLoginForm.validate().then((success) => {
        if (success) {
          //console.log("handleSubmit: ", this.sellerEmail)

          this.isLoading = true;

          let sendData = {
            email: this.sellerEmail,
            password: this.sellerPassword,
          };

          let bodyFormData = new FormData();
          bodyFormData.append("username", sendData.email);
          bodyFormData.append("password", sendData.password);
          bodyFormData.append("_wp_http_referer", "/my-account/");
          bodyFormData.append("woocommerce-login-nonce", this.nonce);
          bodyFormData.append("login", "Login In");

          let newURL = window.location.protocol + "//" + window.location.host;

          //let wpLoginResp = await this.wpLogin(newURL, bodyFormData);

          AuthServices.SignIn(sendData)
            .then((response) => {
              console.log("SIGNIN: " + JSON.stringify(response.data));
              let varientVal = "";

              if (response.data.status) {
                localStorage.setItem("token", response.data.data.token);
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(response.data.data.user)
                );

                this.$store.commit(
                  "user/SET_USER_DETAILS",
                  response.data.data.user
                );

                const user = response.data.data.user;

                axios({
                  method: "post",
                  url: newURL + "/my-account",
                  data: bodyFormData,
                  headers: { "Content-Type": "application/html" },
                }).then(function (resp) {
                  console.log("ap_login_resp: " + JSON.stringify(resp.data));
                  //this.isLoading = false;
                  if (user.user_role_id == 1) {
                    /* if (this.formType == "LOGIN") {
                      window.location.replace("/sell/sell_refurbish");
                      //this.$router.push("/sell_refurbish");
                    } else {
                      window.location.replace(
                        "sell/account-setting?payout=true"
                      );
                    } */
                    window.location.replace("/sell/sell_refurbish");
                  } else {
                    /* if (this.formType == "LOGIN") {
                      window.location.replace("/sell/dashboard");
                      //this.$router.push("/sell_refurbish");
                    } else {
                      window.location.replace(
                        "sell/account-setting?payout=true"
                      );
                    } */
                    window.location.replace("/sell/dashboard");
                  }
                });
                /* .catch(function (err) {
                    console.log(err);
                  }); */

                /* if (user.user_role_id == 1) {
                  if (this.formType == "LOGIN") {
                    window.location.replace("/sell/sell_refurbish");
                    //this.$router.push("/sell_refurbish");
                  } else {
                    window.location.replace("sell/account-setting?payout=true");
                  }
                } else {
                  if (this.formType == "LOGIN") {
                    window.location.replace("/sell/dashboard");
                    //this.$router.push("/sell_refurbish");
                  } else {
                    window.location.replace("sell/account-setting?payout=true");
                  }
                } */
                varientVal = "success";
              } else {
                this.isLoading = false;
                varientVal = "error";
              }

              //this.isLoading = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "UserIcon",
                  variant: varientVal,
                },
              });
            })
            .catch((error) => {
              this.isLoading = false;
              this.errorMessage = error.message;
              console.error("There was an error SignIn !", error);
              //window.location.replace("sell/");
            });
        }
      });
    },

    collapseClicked(item) {
      this.PageTitle = "";
      if (this.flag == false) {
        this.PageTitle = item.PageFaq;
        this.flag = true;
      } else {
        this.PageTitle = "";
        this.flag = false;
      }
    },

    validationForm() {
      this.$refs.registerForm.validate().then((success) => {
        console.log(success);
        if (success) {
          if (this.check2Status) {
            this.createSeller();
          } else {
            this.toastMessage(
              "Please accept the privacy policy & terms to continue.",
              "danger",
              "AlertTriangleIcon"
            );
          }
        }
      });
    },

    async createSeller() {
      //this.isLoading = true;

      if (
        this.registerForm.phone_code == null ||
        this.registerForm.phone_code == ""
      ) {
        this.toastMessage(
          "Dial code is required",
          "danger",
          "AlertTriangleIcon"
        );
        return;
      }

      const sendData = {
        firstname: this.capitalizeFL(this.registerForm.fname),
        lastname: this.capitalizeFL(this.registerForm.lname),
        email: this.registerForm.email,
        phone: this.registerForm.phone,
        phone_code: this.registerForm.phone_code,
        password: this.registerForm.password,
        country_code: this.registerForm.country,
        newsletter: this.registerForm.newsletter,
      };

      try {
        let response = await AuthServices.RegisterUser(sendData);

        if (response.data.status) {
          this.isLoading = false;
          //this.swal("Account Created", response.data.message, "success");

          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem(
            "userInfo",
            JSON.stringify(response.data.data.user)
          );

          this.$store.commit("user/SET_USER_DETAILS", response.data.data.user);

          this.toastMessage("Account Created", "success", "UserIcon");
          //this.$router.push("/sell_refurbish");
          window.location.replace("/sell/sell_refurbish");
        } else {
          this.isLoading = false;
          this.toastMessage(response.data.message, "danger", "UserIcon");
        }
      } catch (error) {
        this.isLoading = false;
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      }
    },

    capitalizeSentance(strings) {
      var splitString = strings.split(".");
      console.log(splitString.length);
      let finalDescp = null;
      for (let index = 0; index < splitString.length; index++) {
        const sentance = splitString[index].trim();
        if (index == 0) {
          finalDescp = this.capitalizeFL(sentance);
        } else {
          finalDescp = finalDescp + ". " + this.capitalizeFL(sentance);
        }
      }
      console.log(finalDescp);
    },

    capitalizeFL(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    titleCase(str) {
      return str
        .split(" ")
        .map(function (val) {
          return val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
        })
        .join(" ");
    },

    toastMessage(message, type, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: icon,
          variant: type,
        },
      });
    },

    swal(title, message, type) {
      new swal({
        title: title,
        text: message,
        type: type,
        showOkButton: true,
        closeOnConfirm: false,
      });
    },

    onCancel() {
      console.log("Seller cancelled the loader.");
    },

    checkForm(type) {
      console.log("type: ", type);
      this.formType = type;
    },

    async subscribe() {
      if (this.sub_email != null) {
        const sendData = { email: this.sub_email };
        let response = await AuthServices.SubscribeUser(sendData);
        if (response.data.status) {
          this.sub_email = null;
          this.toastMessage(response.data.message, "success", "MailIcon");
        } else {
          this.toastMessage(response.data.message, "danger", "MailIcon");
        }
      } else {
        this.toastMessage(
          "Please enter valid email address",
          "danger",
          "MailIcon"
        );
      }
    },

    loadChat() {
      this.interval = setInterval(() => {
        if (window.MessageBirdChatWidget) {
          window.MessageBirdChatWidget.show();
          clearInterval(this.interval);
        }
      }, 500);
    },
  },
  beforeMount() {
    
    if (window.location.href.indexOf("www") > -1) {
      var ogurl = `${window.location.href}`;
      var split = ogurl.split("//");
      var newurl = "https://www." + split[1].replace("www.", "");
      console.log("OG_URL: ", `${window.location.href}`);
      console.log("NEW_URL", newurl);
      //location.replace(newurl);
    } else {

      console.log("else www", `${window.location.href}`);

      var ogurl = `${window.location.href}`;
      var split = ogurl.split("//");
      var newurl = split[0] + "//www." + split[1].replace("www.", "");
      window.location.href = newurl
      console.log("newurl : ", newurl)

    }

    this.loadChat();
    this.getNonce();
  },
};
</script>

<style >
html {
  scroll-behavior: smooth !important;
}
.row {
  margin-right: 0;
  margin-left: 0;
}
.back_to_home {
  position: fixed;
  letter-spacing: 1px;
  left: -54px;
  bottom: 200px;
  z-index: 999;
  text-transform: uppercase;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}
.back_to_home a {
  background: #3a4b43;
  color: #fff;
  padding: 6px 10px;
}

.modal-header {
  justify-content: center;
  padding: 1rem;
}
.modal-header .close {
  display: none !important;
}
.modal-header .modal-title {
  font-size: 1.5rem;
}
.modal-body {
  padding: 1rem 3rem;
}
.modal-body label {
  font-size: 1.2rem;
}
.modal-body input {
  font-size: 1.2rem;
  border-radius: 0;
}
.modal-footer {
  justify-content: center !important;
}
.modal-footer button {
  padding: 1rem 3rem;
  border-radius: 0;
}
.modal-footer .btn-primary {
  background-color: #3a4b43 !important;
  border-color: #3a4b43 !important;
}
.modal-body .input-group-text {
  border-radius: 0;
}
.seller_form_container {
  width: 95%;
}
.banner-text {
  margin-top: 2.5rem;
}
.banner-text p {
  font-size: 2rem;
  word-spacing: 2px;
  color: #ad9c8d;
  margin-bottom: 1.3rem;
  line-height: 1.3;
}
.banner-text h1 {
  font-size: 2rem;
  font-weight: 900;
  color: #3a4b43;
}
.seller_form {
  background: transparent;
  font-size: 1.5rem;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 2px solid #8e928f;
  border-radius: 0;
  padding: 1.5rem 0.5rem;
}
.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none;
  border-radius: 0;
}

.seller_form::placeholder {
  color: #9da09d;
}
.seller_form:focus {
  box-shadow: none !important;
  background: transparent !important;
}
.btn-seller {
  border-radius: 0 !important;
  outline: 0;
  padding: 1.4rem 4rem;
  background-color: #3a4b43 !important;
  color: #d8cfc6 !important;
}
.seller_text {
  font-size: 1.4rem;
  color: #3a4b43;
  margin-bottom: 2rem;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0px;
  width: 1.9rem;
  height: 1.9rem;
  background: transparent;
  border: 2px solid #3a4b43;
}
.custom-checkbox .custom-control-label::after {
  width: 1.9rem;
  height: 1.9rem;
}
.custom-checkbox .custom-control-label {
  font-size: 1.4rem;
  color: #3a4b43;
  padding-left: 1.5rem;
}
.seller_link {
  color: #3a4b43;
  font-weight: 900;
  text-decoration: underline;
  letter-spacing: 1px;
}
.text_link {
  font-size: 1rem;
}
.p-10 {
  padding: 6rem;
}
.bg_light {
  background: #fff !important;
}
.card-header {
  background: #fff !important;
  padding-right: 4rem;
  padding-top: 0.2rem;
}
.tab-10 .card-header {
  padding-top: 0%;
}
.nav-pills .nav-link {
  letter-spacing: 2px;
  padding-left: 0%;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  justify-content: start;
  padding-bottom: 3px;
}
.nav-pills .nav-link.active {
  border-top: 0;
  box-shadow: none;
  background-color: transparent;
  color: #3a4b43;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  font-weight: 700;
  padding-bottom: 3px;
  letter-spacing: 2px;
  border-bottom: 1px solid #636780;
  display: inline-block;
}
.Shop_page {
  padding: 5rem 0;
}
.Shop_page h1 {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 3px;
  margin-bottom: 1rem;
  color: #3a4b43;
}
.Shop_page p {
  font-size: 1.3rem;
  color: #9f9f9f;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
}
.input_email {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  background: #e1d7ce;
  border-radius: 0;
  text-align: center;
  height: 4.5rem;
  font-size: 1.5rem;
}
.tab_heading h1 {
  font-weight: 900;
  color: #3a4b43;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.tab_heading .card-text p {
  font-size: 1.3rem;
  color: #3a4b43;
  line-height: 1.5;
  text-align: justify;
}
.card-body {
  padding: 0 2rem 5rem 7rem;
  border-left: 1px solid #3a4b43;
}
.tab_heading .tab-11::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url("../../../assets/images/icons/user_account1.png");
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.tab_heading .tab-1::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url("../../../assets/images/icons/user_gallary2.png");
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.tab_heading .tab-2::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url("../../../assets/images/icons/user_auth3.png");
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.tab_heading .tab-3::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url("../../../assets/images/icons/user_money4.png");
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.tab_heading .tab-4::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url("../../../assets/images/icons/user_payment5.png");
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.tab_heading .tab-5::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url("../../../assets/images/icons/user_value6.png");
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.tab_heading .tab-6::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url("../../../assets/images/icons/sellerdashboard7.png");
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.tab_heading .tab-7::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url("../../../assets/images/icons/transport8.png");
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.tab_heading .tab-8::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url("../../../assets/images/icons/revivify9.png");
  background-size: cover;
  top: -10px;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.tab_heading .tab-9::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url("../../../assets/images/icons/revivify9.png");
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.tab_heading .tab-10::before {
  content: "";
  position: absolute;
  width: 80px;
  background: url("../../../assets/images/icons/revivify9.png");
  background-size: cover;
  height: 80px;
  left: -40px;
  border-radius: 50%;
}
.seller_Pay {
  font-size: 1.3rem;
  color: #3a4b43;
  line-height: 1.5;
  text-align: justify;
}
.sell_subscribe {
  width: 65%;
  margin: auto;
}
.sell_form {
  padding: 0 2rem;
}
.tab-10 .card {
  box-shadow: none !important;
}
.tab-10 .card-body {
  border-left: 0 !important;
  font-size: 1.1rem !important;
}
.tab-10 .card-header {
  padding-bottom: 0 !important;
}
.tab-10 .collapse-title {
  font-size: 1.6rem !important;
  font-weight: 800 !important;
  letter-spacing: 1.5px !important;
  line-height: 1.4 !important;
  padding-bottom: 0 !important;
}
.tab-10 .pl-2 {
  padding: 0 !important;
}

.collapse-icon [data-toggle="collapse"]:after {
  background-image: none !important;
}

.footer-light_n {
  background-color: #3a4b43;
}

section.header {
  background-color: white;
}
.seller_country {
  background-color: #7fffd400;
  background: #7fffd400 !important;
  border: 0px;
  border-bottom: 2px solid #8e928f;
  border-radius: 0px;
  margin: 0;
  padding: 0;
  /* font-size: larger; */
  height: 3.2rem;
  font-size: 1.5rem;
  color: #9da09d;
}

.seller_country:focus {
  box-shadow: none !important;
}
.eye_icon .input-group-text {
  background: transparent;
  border: 0;
  border-bottom: 2px solid #8e928f;
  border-radius: 0;
}
.collapse-margin .card {
  margin-bottom: 1.71rem !important;
  margin-top: 0 !important;
}
.collapse-margin .card .card-header {
  padding-top: 0 !important;
}
.text_p {
  font-size: 1.3rem !important;
  color: #3a4b43 !important;
  line-height: 1.5 !important;
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0;
  padding: 0.5rem 2.2rem;
  background: #3a4b43;
}
.forgot_btn {
  margin-top: 2px;
}
.tel_input_main .phone_input input {
  padding-left: 85px;
}
.tel_input_main .code_input {
  font-size: 1.5rem;
  color: #6e6b7b !important;
  position: absolute;
  top: 0;
  width: 85px;
}
.vs--open .vs__dropdown-toggle {
  box-shadow: none !important;
}

.tel_input_main .code_input .vs__dropdown-toggle {
  border: none;
  padding: 0;
}
.tel_input_main .code_input .vs__dropdown-toggle .vs__actions {
  display: none !important;
}
.tel_input_main .code_input .vs__search::placeholder {
  color: #9da09d;
}
.tel_input_main .code_input .vs__search {
  margin-top: 2px;
}
.vs__dropdown-menu {
  min-width: 350px !important;
}
.arrow_btn_fq a {
  padding: 15px 10px;
  background: #3a4b43;
  border-radius: 50%;
  margin: 4px;
}
.arrow_btn svg {
  width: 30px;
  height: 30px;
  color: #fff;
}
.arrow_btn_fq {
  position: fixed;
  bottom: 2rem;
  left: 1rem;
  z-index: 99999;
}

p.fs-20 {
  font-size: 20px;
  word-spacing: 2px;
  color: #ad9c8d;
  margin-bottom: 1.3rem;
  margin-top: 1rem;
  line-height: 1.3;
}

/*--------------------------- max-width 992px Start---------------------- */

@media (max-width: 992px) {
  .seller_form_container {
    padding: 0 3rem;
  }
  .sell_subscribe {
    width: 100%;
  }
  .p-10 {
    padding: 3rem 1rem;
  }
  .seller_form_container {
    width: 100%;
  }

  .card-body {
    padding: 0px 1rem 0 5rem;
  }
  .card-text h1 {
    text-align: left;
  }
}

/*--------------------------- max-width 992px End ---------------------- */

/*--------------------------- max-width 768px ---------------------- */
@media (max-width: 768px) {
  .tab-content {
    margin-left: 5rem;
  }
  .tab_heading h1 {
    font-size: 1.5rem;
  }
  .col-auto {
    width: 100%;
  }
  .banner-text p {
    font-size: 1.5rem;
  }
  .banner-text h1 {
    font-size: 1.5rem;
  }
}

/*--------------------------- max-width 768px ---------------------- */

/*--------------------------- max-width 418px ---------------------- */
@media (max-width: 576px) {
  .revivify-logo {
    height: 42px;
  }
  .tab-content {
    margin-left: 3rem;
  }
  .card-body {
    padding: 0px 1rem 0 4rem;
  }
  .btn-seller {
    padding: 1.2rem 2rem;
  }
  .seller_form {
    font-size: 1.3rem;
    border-bottom: 1px solid #8e928f;
  }
  .eye_icon .input-group-text {
    border-bottom: 1px solid #8e928f;
  }
  .seller_country {
    border-bottom: 1px solid #8e928f;
    font-size: 1.3rem;
  }
  .seller_form_container {
    padding: 0 1rem;
  }
  .custom-checkbox .custom-control-label {
    font-size: 1rem;
  }
  .custom-checkbox .custom-control-label::before {
    border: 1px solid #3a4b43;
    height: 1.3rem;
    width: 1.3rem;
    margin-top: 0.3rem;
  }
  .custom-checkbox .custom-control-label::after {
    height: 1.3rem;
    width: 1.3rem;
    margin-top: 0.3rem;
  }
  .seller_text {
    font-size: 1rem;
  }
  .p-10 {
    padding: 3rem 0;
  }
  .banner-text p {
    font-size: 1.3rem;
  }
  .banner-text h1 {
    font-size: 1.3rem;
  }
  .custom-checkbox .custom-control-label {
    padding-left: 0.3rem;
  }
  .nav-pills .nav-link.active {
    font-size: 1rem;
  }
  .nav-pills .nav-link {
    font-size: 1rem;
  }
  .card-header {
    padding-left: 0;
  }
  .tab_heading .card-text p {
    font-size: 1rem;
  }
  .tab_heading .tab-11::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .tab_heading .tab-1::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .tab_heading .tab-2::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .tab_heading .tab-3::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .tab_heading .tab-4::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .tab_heading .tab-5::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .tab_heading .tab-6::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .tab_heading .tab-7::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .tab_heading .tab-8::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .tab_heading .tab-9::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .tab_heading .tab-10::before {
    width: 60px;
    height: 60px;
    left: -30px;
  }
  .tab_heading h1 {
    font-size: 1.3rem;
  }
  .seller_Pay {
    font-size: 1rem;
  }
  .input_email {
    font-size: 1rem;
  }
}

/*--------------------------- max-width 418px end ---------------------- */
</style>
