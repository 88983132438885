<template>
  <div>
    <b-row class="mt-2">
      <b-col md="2"></b-col>
      <b-col md="2" v-for="(fitem, index) in footer_menu" :key="index + 'foot'">
        <h4 class="text-white mb-1">
          <b>{{ fitem.title }}</b>
        </h4>
        <ul
          style="list-style-type: none; padding: 0px"
          v-for="item in fitem.data"
          :key="item.name"
        >
          <li class="wp-footer-menu-li">
            <a class="footer-menu-link" :href="item.link">{{ item.name }}</a>
          </li>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BLink, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BRow,
    BCol,
  },

  data() {
    return {
      footer_menu: [
        /* {
          title: "QUICK LINKS",
          data: [
            {
              name: "Brands",
              link: "https://uat.revivify.com/",
            },
            {
              name: "Trends",
              link: "https://uat.revivify.com/",
            },
            {
              name: "New Arrivals",
              link: "https://uat.revivify.com/",
            },
            {
              name: "Staff Picks",
              link: "https://uat.revivify.com/",
            },
            {
              name: "Sale",
              link: "https://uat.revivify.com/",
            },
          ],
        }, */
        {
          title: "COMPANY",
          data: [
            {
              name: "About Us",
              link: "/about-us",
            },
            {
              name: "Customer Care",
              link: "https://www.revivify.com/contact-us-revivify/",
            },
          ],
        },
        {
          title: "INFORMATION",
          data: [
            {
              name: "FAQ's",
              link: "https://revivify.com/faqs/",
            },
            {
              name: "Brands We Accept",
              link: "https://www.revivify.com/designers/",
            },
          ],
        },
        // {
        //   title: "SOCIAL",
        //   data: [
        //     {
        //       name: "Facebook",
        //       link: "https://uat.revivify.com/",
        //     },
        //     {
        //       name: "Twitter",
        //       link: "https://twitter.com/Revivify_HQ",
        //     },
        //     {
        //       name: "Instagram",
        //       link: "https://www.instagram.com/revivifyhq/",
        //     },
        //     {
        //       name: "TikTok",
        //       link: "https://uat.revivify.com/",
        //     },
        //   ],
        // },
      ],
    };
  },
};
</script>


<style>
.wp-footer-menu-li > a {
  color: #fff !important;
}
</style>